import {
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	signal,
	SimpleChanges,
	ViewChild,
	WritableSignal
} from "@angular/core";
import { FormBuilder, FormControl, FormGroup, UntypedFormControl, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatStepper } from "@angular/material/stepper";
import { Browser } from "@capacitor/browser";
import { ModalController, NavController, Platform } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import * as _ from "lodash";
import { DateTime } from "luxon";
import {
	Subject,
	Subscription,
	combineLatest,
	debounceTime,
	distinctUntilChanged,
	firstValueFrom,
	from,
	of,
	switchMap
} from "rxjs";
import { TypeCustomFields, TypeFieldDataString } from "src/app/shared/enums/type-custom-fields";
import { FormItemType } from "src/app/shared/enums/type-register-forms";
import { IDocument, IEvent, IEventUser, IFullCustomField, IModule, ISchedule, ITrack } from "src/app/shared/interfaces";
import {
	ICountry,
	ICustomField,
	ICustomFieldData,
	IField,
	IFile,
	IImage,
	IModuleCustomField,
	IMultipleTextField,
	IPlacesPredictions
} from "src/app/shared/interfaces/custom-fields.interfaces";
import { ILanguage, ILanguageArray } from "src/app/shared/interfaces/languages.interfaces";
import {
	IRegisterForm,
	IRegisterFormItem,
	IRegisterFormSection
} from "src/app/shared/interfaces/register-form.interfaces";
import { PathComponents } from "src/app/shared/paths/path-components";
import {
	registerFormLastSectionCreate,
	registerFormLastSectionLogin
} from "src/app/shared/reducers/register-form.datas";
import {
	AuthService,
	CustomFieldsService,
	EventUsersService,
	FirestoreService,
	FormsService,
	SchedulesService,
	StorageService,
	UtilityService
} from "src/app/shared/services";
import { environment } from "src/environments/environment";
import { Store } from "@ngrx/store";
import { TypeModule } from "src/app/shared/enums/type-module";
import { getModulesByTypes } from "src/app/shared/selectors/modules.selectors";
import { CountryISO, PhoneNumberFormat, SearchCountryField } from "ngx-intl-tel-input";
import { countriesIsoCode } from "src/app/shared/datas/countries-code";
import { Router } from "@angular/router";

@Component({
	selector: "app-form-fields",
	templateUrl: "./form-fields.component.html",
	styleUrls: ["./form-fields.component.scss"]
})
export class FormFieldsComponent implements OnInit, OnDestroy, OnChanges {
	@ViewChild("stepper") stepper: MatStepper;
	@Input() event: IEvent;
	@Input() module: IModule;
	@Input() registerForm: IRegisterForm;
	@Input() computedCustomFields: IFullCustomField[];
	@Input() currentLanguage: string;
	@Input() mode: string;
	@Input() loader: boolean;
	@Input() messages: { errorMsg: string; successMsg: string; warningMsg: string };
	@Input() lockNextButton: boolean;
	@Input() loginMode: boolean;
	@Input() eventUser?: IEventUser;
	@Input() recaptchaStatus?: boolean;

	@Output() validateFields: EventEmitter<{ formDatas: any; registerForm: IRegisterForm }> = new EventEmitter();
	@Output() emailFilled: EventEmitter<{ hasValue: boolean }> = new EventEmitter();

	@ViewChild("profileImageInput") profileImageInput: ElementRef;

	eventUserCopy: IEventUser;

	subscriptions: Subscription[] = [];
	passwordCtrlSub: Subscription;
	sections: IRegisterFormSection[];

	form: FormGroup;
	actualStep: number = 0;
	currentStepId: string;

	attendees: IEventUser[] = [];
	speakers: IEventUser[] = [];
	tracks: ITrack[] = [];
	sessions: ISchedule[] = [];
	customDocuments: IDocument[] = [];

	// Module type fields properties
	registeredDatasForField: { moduleId: string; sessions: ISchedule[] }[] = [];
	toRegisterModules: IModule[] = [];

	// SELECT INPUT PROPERTIES
	actualIndexOption: number;
	changeOperation: { uid: string; operations: string[] }[] = [];

	// MULTIPLE TEXT PROPERTIES
	multipleTextValues: IMultipleTextField[] = [];
	multipleTextInputError: {
		controlId: string;
		msg: string;
	}[] = [];

	// ADDRESS FIELD PROPERTIES
	placesPredictions: IPlacesPredictions[] = [];
	addressSubscriptions: Subscription[] = [];

	// FILES/ IMAGES PROPERTIES
	customFieldMediaItems: { uid: string; data: File }[] = [];
	readonly MAX_FILE_SIZE = 5242880; // 5Mo
	invalidSizeImg: boolean;
	actualFiles: { customFieldId: string; data: File }[] = [];
	isValidUrl = false;

	// Types
	typeCustomFields = TypeCustomFields;
	typeFieldDataString = TypeFieldDataString;
	formItemType = FormItemType;
	typeModule = TypeModule;

	// states
	buttonLoader = false;
	emailButtonLoader = false;
	profilPictureLoader = false;
	isMobile = false;

	// Tiny API Key

	tinyInitDesktop: any = environment.platform.tinyMce.init;
	tinyInitMobile: any = environment.platform.tinyMce.initMobile;

	// Stepper properties
	nextSectionId: string = null;
	previousSectionId: string = null;
	stepIndexes: number[] = [];
	enableLinearBehavior: boolean = false;

	// password field propertiies
	showPwd: boolean = false;
	showConfirmPwd: boolean = false;
	validPassword: boolean = false;
	stepObserver$: Subject<number> = new Subject<number>();
	submitSectionExist: boolean = false;
	actualImage: any;
	stepValidity: boolean;
	previousStepValidity: boolean;

	realTimeCountValues: {
		[key: string]: {
			datas: {
				countValues: any[];
				tempCountValues: any[];
			};
		};
	};

	// Phone tel properties
	countriesIsoCode = countriesIsoCode;
	preferedCountries: CountryISO[] = [
		CountryISO.France,
		CountryISO.UnitedStates,
		CountryISO.UnitedKingdom,
		CountryISO.Spain,
		CountryISO.Switzerland,
		CountryISO.Belgium,
		CountryISO.Canada
	];
	SearchCountryField = SearchCountryField;
	CountryISO = CountryISO;
	PhoneNumberFormat = PhoneNumberFormat;
	phoneInitiated = false;
	itemsValues: { [key: string]: any } = {};
	optionLimitSub: Subscription;
	componentOs: string;
	isModal: boolean = false;

	// Other field manual validators
	isProfilPictureValid: boolean = true;
	formLoader: WritableSignal<boolean> = signal(true);

	constructor(
		private modalCtrl: ModalController,
		private snackbar: MatSnackBar,
		private formBuilder: FormBuilder,
		public STranslate: TranslateService,
		private platform: Platform,
		private storage: StorageService,
		private SCustomFields: CustomFieldsService,
		private SFirestore: FirestoreService,
		private SSchedules: SchedulesService,
		private navCtrl: NavController,
		private store: Store,
		public SForms: FormsService,
		private SAuth: AuthService,
		public SUtility: UtilityService,
		private router: Router,
		private SEventUsers: EventUsersService
	) {
		this.platform.is("mobile") && window.innerWidth < 768 && (this.isMobile = true);
		this.componentOs = this.platform.is("ios") ? "ios" : "md";
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (
			changes.currentLanguage &&
			!_.isEqual(changes.currentLanguage.previousValue, changes.currentLanguage.currentValue)
		) {
			this.currentLanguage = changes.currentLanguage.currentValue;
			this.initForm();
		}
	}

	ngOnInit(): void {
		this.isModal = this.mode && this.mode !== "" && !this.router.url.includes("register-form");
		this.getRegisteredModules();
		this.getSessions();
		this.getModuleTempOptionCusValues();
		this.initForm();
	}

	ngOnDestroy(): void {
		this.subscriptions
			.concat([this.passwordCtrlSub, this.optionLimitSub])
			.filter((sub) => sub)
			.forEach((sub) => sub.unsubscribe());

		this.destroyForm();
	}

	/**
	 * initForm
	 */
	initForm() {
		if (
			this.event?.settings?.enableMultiLanguage ||
			(!this.event?.settings?.enableMultiLanguage && this.currentLanguage === this.event.language)
		) {
			!this.mode && (this.mode = "register-modal");
			this.eventUserCopy = _.cloneDeep(this.eventUser);

			this.sections =
				this.mode === "accompanying-form-modal"
					? this.registerForm.formContent.accompanyingSections
					: this.registerForm.formContent.sections;

			this.form = this.formBuilder.group(
				this.sections
					.map((section) => section.uid)
					.reduce((obj, item) => Object.assign(obj, { [item]: this.formBuilder.group({}) }), {})
			);

			this.sections.forEach((section, sectionIndex) => {
				const formGroup = this.form.get(section.uid) as FormGroup;

				Object.entries(this.event.languagesSettings).forEach(([key, value]) => {
					if (value) {
						(section.items = section.items.filter((item) => !item.hidden)).forEach((item) => {
							switch (item.type) {
								case FormItemType.INPUT:
									{
										this.initTemporaryValues(item);
										const itemValue =
											this.eventUserCopy && this.mode === "fill-form-modal"
												? this.getItemValue(section, item, key)
												: null;

										formGroup.addControl(
											!this.SForms.isMultiLanguageField(item) ? item.uid : `${item.uid}_${key}`,
											new FormControl(
												{
													value:
														this.eventUserCopy && this.mode === "fill-form-modal"
															? itemValue
															: null,
													disabled:
														this.eventUserCopy &&
														item.uniqueEdit &&
														(itemValue ||
															(item.inputFieldType === TypeCustomFields.MULTI_TEXT &&
																this.multipleTextValues.find(
																	(field) =>
																		field.uid ===
																		(item.value as IFullCustomField)?.baseSettings
																			?.uid
																)?.multiLanguageTextArray[key].length > 0))
															? true
															: false
												},
												{
													validators: this.getValidators(item),
													asyncValidators: [],
													updateOn: this.SForms.isTextControlType(item.inputFieldType)
														? "blur"
														: "change"
												}
											)
										);

										// if (
										// 	item.inputFieldType === TypeCustomFields.PHONE ||
										// 	item.inputFieldType === TypeCustomFields.COUNTRY ||
										// 	item.inputFieldType === TypeCustomFields.ADDRESS
										// ) {
										// 	this.patchComplexValueFormItem(
										// 		section,
										// 		item,
										// 		this.getItemValue(section, item, this.itemsValues[item.uid])
										// 	);
										// }
										this.fieldsSubscriptions(item, formGroup, section, sectionIndex, key);
									}
									break;

								case FormItemType.MODULE: {
									formGroup.addControl(
										item.isBaseField ? item.uid : `${item.uid}_${key}`,
										new UntypedFormControl(
											this.mode === "fill-form-modal"
												? this.getItemValue(section, item, key)
												: null,
											this.getValidators(item)
										)
									);

									if (
										this.getSpecificModule((item.value as IModule)?.uid) &&
										this.getSpecificModule((item.value as IModule)?.uid).type ===
											TypeModule.SCHEDULE
									) {
										this.registeredDatasForField.push({
											moduleId: (item.value as IModule)?.uid,
											sessions: []
										});
									}
									break;
								}
							}
						});
					}
				});
			});

			if (this.sections.length === 1 && (this.mode === "register-modal" || this.mode === "register")) {
				this.addSubmitSection();
			}

			this.formLoader.set(false);
		}
	}

	destroyForm() {
		this.formLoader.set(true);
		this.form = null;
		this.sections = null;
		this.multipleTextValues = [];
		this.multipleTextInputError = [];
		this.placesPredictions = [];
		this.addressSubscriptions = [];
		this.customFieldMediaItems = [];
		this.actualFiles = [];
		this.itemsValues = {};
		this.changeOperation = [];
		this.realTimeCountValues = {};
		this.optionLimitSub?.unsubscribe();
	}

	/**
	 *initTemporaryValues
	 * @param item
	 */
	initTemporaryValues(item: IRegisterFormItem) {
		if (
			item.inputFieldType === TypeCustomFields.MULTI_TEXT &&
			!this.multipleTextValues.find((field) => field.uid === (item.value as IFullCustomField)?.baseSettings?.uid)
		) {
			this.multipleTextValues.push({
				uid: item.uid,
				multiLanguageTextArray: {
					ArAR: [],
					DeDE: [],
					EnUS: [],
					EsES: [],
					FrFR: [],
					PtBR: []
				} as ILanguageArray
			});
		}
	}

	/**
	 * patchComplexValueFormItem
	 * @param section
	 * @param item
	 * @param value
	 */
	patchComplexValueFormItem(section: IRegisterFormSection, item: IRegisterFormItem, value: any) {
		const formGroup = this.form.get(section.uid) as FormGroup;
		const itemValue = formGroup.get(item.uid);

		if (itemValue) {
			if (item.inputFieldType === TypeCustomFields.COUNTRY) {
				const sectionIndex = this.sections.findIndex((sct) => sct.uid === section.uid);
				const itemIndex = this.sections[sectionIndex].items.findIndex((itm) => itm.uid === item.uid);

				this.sections[sectionIndex].items[itemIndex].value = value;
				this.form
					.get(section.uid)
					.get(item.uid)
					.patchValue((value as ICountry)?.name);
			}
		}
	}

	/**
	 * fieldsSubscriptions
	 * @param item
	 * @param section
	 * @param sectionIndex
	 * @param key
	 */
	fieldsSubscriptions(
		item: IRegisterFormItem,
		formGroup: FormGroup,
		section: IRegisterFormSection,
		sectionIndex: number,
		lang: string
	) {
		// subscribe to value changes so the same value is set for all activated languages
		if (item.inputFieldType === TypeCustomFields.TEXT && !item.isBaseField) {
			this.subscriptions.push(
				this.form
					.get(section.uid)
					.get(`${item.uid}_${lang}`)
					.valueChanges.pipe(debounceTime(300), distinctUntilChanged())
					.subscribe((value) => {
						Object.entries(this.event.languagesSettings)
							.filter(([_, isActive]) => isActive)
							.forEach(([key, _]) => {
								if (key !== lang) {
									this.form.get(section.uid).get(`${item.uid}_${key}`).setValue(value);
								}
							});
					})
			);
		}

		if (item.isBaseField && item.inputFieldType === TypeCustomFields.EMAIL) {
			this.form
				.get(section.uid)
				.get(item.uid)
				.valueChanges.pipe(debounceTime(300), distinctUntilChanged())
				.subscribe((value) => {
					this.emailFilled.emit({ hasValue: value });
				});
		}

		if (
			!item.isBaseField &&
			(item.inputFieldType === TypeCustomFields.IMAGE || item.inputFieldType === TypeCustomFields.FILE)
		) {
			if (item.inputFieldType === TypeCustomFields.IMAGE)
				formGroup.addControl(
					`${item.uid}_URL`,
					new UntypedFormControl(
						this.mode === "fill-form-modal" ? this.getItemValue(section, item, lang) : null
					)
				);

			if (!this.customFieldMediaItems.find((mediaItem) => mediaItem.uid === item.uid))
				this.customFieldMediaItems.push({
					uid: item.uid,
					data: null
				});
		}

		if (item.inputFieldType === TypeCustomFields.SELECT) {
			// Conditionnal field computation
			if (item.enableRules) {
				this.subscriptions.push(
					this.stepObserver$.subscribe((step) => {
						if (step !== sectionIndex) return;

						const value = this.form.get(section.uid).get(`${item.uid}_${lang}`).value;

						if (value) {
							this.setNextSectionValue(value[lang], lang, item);
						}
					})
				);

				this.subscriptions.push(
					combineLatest([
						this.stepObserver$,
						this.form.get(section.uid).get(`${item.uid}_${lang}`).valueChanges
					]).subscribe(([step, value]) => {
						if (step !== sectionIndex) return;

						if (value && value !== "") {
							this.setNextSectionValue(value[lang], lang, item);
						}
					})
				);
			}

			// Init operations changes fields in case of fields count limit func enabled
			if ((item.value as IFullCustomField)?.baseSettings.enableSelectLimit) {
				if (
					this.changeOperation.findIndex(
						(op) => op.uid === (item.value as IFullCustomField).baseSettings.uid
					) === -1
				) {
					this.changeOperation.push({
						uid: (item.value as IFullCustomField).baseSettings.uid,
						operations: (item.value as IFullCustomField).baseSettings.options.map((_) => null)
					});
				}
			}
		} else if (item.inputFieldType === TypeCustomFields.ADDRESS) {
			// Adress field control
			this.placesPredictions.push({
				controlId: item.uid,
				data: [],
				loading: false,
				searchValue$: new Subject<string>()
			});

			this.subscriptions.push(
				this.placesPredictions
					.find((pred) => pred.controlId === item.uid)
					?.searchValue$.pipe(
						debounceTime(1500),
						distinctUntilChanged(
							(a: string, b: string) => a.trim().toLowerCase() === b.trim().toLowerCase()
						)
					)
					.subscribe((value) => {
						this.getPlacePredictions(value, item.uid);
					})
			);
		}
	}

	/**
	 * getSessions
	 */
	getSessions() {
		this.subscriptions.push(
			this.SSchedules.getAllSessionsOfModulesFromFile().subscribe((sessions) => {
				if (!_.isEqual(this.sessions, sessions)) {
					this.sessions = sessions;
				}
			})
		);
	}

	/**
	 * setNextSectionValue
	 * @param value
	 * @param key
	 * @param item
	 */
	setNextSectionValue(value: string, key: string, item: IRegisterFormItem): void {
		if (value) {
			const optionIndex = (item.value as IFullCustomField).baseSettings.options.findIndex(
				(opt) => opt[key] === value
			);

			item.rules[`${optionIndex}_${item.uid}`] &&
				(this.nextSectionId = item.rules[`${optionIndex}_${item.uid}`].toString());
		}
	}

	/**
	 * getItemValue
	 * @param item
	 * @param eventUser
	 */
	getItemValue(section: IRegisterFormSection, item: IRegisterFormItem, language: string): any {
		let value: any;
		if (item.isBaseField && item.type === FormItemType.INPUT) {
			if (item.inputFieldType === TypeCustomFields.EMAIL) {
				value = this.eventUserCopy.email;
			} else if (item.inputFieldType === TypeCustomFields.TEXT && item.uid === "name") {
				value = this.eventUserCopy.name;
			}
		} else if (!item.isBaseField && item.type === FormItemType.INPUT) {
			// customfieds
			const index = this.eventUserCopy.customFields.findIndex(
				(cusData) =>
					(item.value as IFullCustomField)?.baseSettings &&
					(item.value as IFullCustomField)?.moduleSettings &&
					cusData.uid === (item.value as IFullCustomField)?.baseSettings.uid &&
					(item.value as IFullCustomField)?.moduleSettings.moduleId === this.eventUserCopy.moduleId
			);

			if (index !== -1) {
				const eventUserCustomFieldDatas = this.eventUserCopy.customFields[index];

				if (
					this.SCustomFields.checkValueCustomField(item.inputFieldType, eventUserCustomFieldDatas, language)
				) {
					value = this.SCustomFields.getValueForCustomField(
						item.inputFieldType,
						eventUserCustomFieldDatas,
						this.event.language,
						language
					);

					if (item.inputFieldType === TypeCustomFields.MULTI_TEXT) {
						this.multipleTextValues[this.getMultiTextInputIndex(item.uid)].multiLanguageTextArray[
							language
						] = value;
						value = "";
					}
				}

				this.itemsValues[item.uid] = eventUserCustomFieldDatas;

				(section.items.find((itm) => itm.uid === item.uid).value as IFullCustomField).fieldDatas =
					eventUserCustomFieldDatas;
			}
		}

		return value || null;
	}

	/**
	 * addPasswordSection
	 */
	addSubmitSection(): void {
		const existedSubmitSection = this.sections.find((section) => section.uid === "submitSection");

		if (existedSubmitSection) {
			this.sections.splice(this.sections.indexOf(existedSubmitSection), 1);
		}
		this.loginMode
			? this.sections.push(registerFormLastSectionLogin)
			: this.sections.push(registerFormLastSectionCreate);

		const formSectionsNbr = this.sections.length;

		this.sections[formSectionsNbr - 2].defaultNextSectionId = this.sections[formSectionsNbr - 1].uid;
		const lastSection = this.sections[this.sections.length - 1];

		this.form.addControl("submitSection", this.formBuilder.group({}));

		lastSection.items.forEach((item) => {
			if (item.type === FormItemType.INPUT)
				(this.form.get("submitSection") as FormGroup).addControl(
					`${item.uid}`,
					new UntypedFormControl(
						item.inputFieldType === TypeCustomFields.CHECKBOX ? false : "",
						this.getValidators(item)
					)
				);
		});

		if (!this.loginMode) {
			if (this.passwordCtrlSub && !this.passwordCtrlSub.closed) {
				this.passwordCtrlSub.unsubscribe();
			}
			this.passwordCtrlSub = combineLatest([
				this.form
					.get("submitSection")
					?.get("password")
					?.valueChanges.pipe(debounceTime(300), distinctUntilChanged()),
				this.form
					.get("submitSection")
					?.get("passwordConfirmation")
					?.valueChanges.pipe(debounceTime(300), distinctUntilChanged())
			]).subscribe((res: string[]) => {
				if (res[0] === res[1] && res[0].length >= 6 && res[1].length >= 6) {
					// Todo: add the strong password validation
					this.validPassword = true;
					this.messages.errorMsg = "";
				} else {
					this.validPassword = false;

					res[0].length >= 6 &&
						res[1].length >= 6 &&
						res[0] !== res[1] &&
						(this.messages.errorMsg = this.STranslate.instant("login.password_not_match"));

					(res[0].length < 6 || res[1].length < 6) &&
						(this.messages.errorMsg = this.STranslate.instant("login.invalid_pass"));
				}
			});
		} else {
			if (this.passwordCtrlSub && !this.passwordCtrlSub.closed) {
				this.passwordCtrlSub.unsubscribe();
			}
			this.passwordCtrlSub = this.form
				.get("submitSection")
				?.get("password")
				?.valueChanges.pipe(debounceTime(300), distinctUntilChanged())
				?.subscribe((res: string) => {
					// Todo: add the strong password validation
					if (res.length >= 6) {
						this.validPassword = true;
						this.messages.errorMsg = "";
					} else {
						this.validPassword = false;
						this.messages.errorMsg = this.STranslate.instant("login.invalid_pass");
					}
				});
		}

		this.submitSectionExist = true;
		this.form.get("submitSection")?.updateValueAndValidity();
	}

	/**
	 * stepChanged
	 * @param event
	 */
	stepChanged(event) {
		this.actualStep = event.selectedIndex;
		this.stepObserver$.next(this.actualStep);

		if (event.previouslySelectedIndex < event.selectedIndex) {
			this.stepIndexes.push(event.previouslySelectedIndex);
			this.previousStepValidity = true;
		} else {
			this.stepIndexes.pop();
		}
	}

	/**
	 * onNextStep
	 * @param event
	 * @param section
	 */
	onNextStep(section: IRegisterFormSection) {
		this.loader = true;
		if (
			(this.actualStep === 0 &&
				(this.mode === "register-modal" || this.mode === "register") &&
				!this.submitSectionExist) ||
			(this.actualStep === 0 && this.loginMode)
		) {
			this.addSubmitSection();
		}

		try {
			setTimeout(() => {
				this.lockNextButton = false;
				this.stepObserver$.next(this.actualStep);

				if (
					this.nextSectionId !== null &&
					(this.nextSectionId !== "submitSection" ||
						(this.nextSectionId === "submitSection" && this.submitSectionExist))
				) {
					this.stepper.selectedIndex = this.sections.findIndex((sct) => sct.uid === this.nextSectionId);
					this.previousSectionId = section.uid;
					this.nextSectionId = null;
				} else {
					if (section.defaultNextSectionId !== null && this.nextSectionId !== "submitSection") {
						this.stepper.selectedIndex = this.sections.findIndex(
							(sct) => sct.uid === section.defaultNextSectionId
						);

						this.previousSectionId = section.uid;
					}
				}

				this.loader = false;
			}, 150);
		} catch (error) {
			if (error.message.includes("out-of-bounds")) {
				console.warn("_");
			} else {
				this.loader = false;

				this.snackbar.open(this.STranslate.instant("snackbar.error-occured"), "", {
					duration: 3000,
					panelClass: "error-snackbar"
				});
			}
		}
	}

	/**
	 * onPreviousStep
	 * @param event
	 * @param section
	 */
	onPreviousStep() {
		this.nextSectionId = null;

		if (this.actualStep === this.stepIndexes[this.stepIndexes.length - 1]) {
			this.stepIndexes.pop();
		}
		this.stepper.selectedIndex = this.stepIndexes[this.stepIndexes.length - 1];
	}

	/**
	 * openLogin
	 * TODO: Implement this function
	 */
	openLogin() {
		if (this.isModal) {
			this.modalCtrl.dismiss({ openLogin: true });
		} else {
			const url = this.registerForm.accessUrl.slice().replace("register-form", "login-form");
			this.navCtrl.navigateRoot(url, { replaceUrl: true });
		}
	}

	/**
	 * @description Push value to array on enter key pressed & clean input
	 * @param $event (control)
	 * @param $array (Array)
	 * @returns void()
	 */
	onEnter($event, index: number, controlName: string, uid: string) {
		const valueFormated = $event?.target?.value?.trim();

		if ($event.keyCode === 13) {
			for (const languageSetting of Object.entries(this.event.languagesSettings)) {
				if (languageSetting[1]) {
					const array = this.multipleTextValues[index].multiLanguageTextArray[languageSetting[0]];

					if (valueFormated) {
						if (valueFormated === "" || valueFormated.length > 70) return;

						// Prevent user to type the same word / phrase multiple time
						if (array?.find((item) => item === valueFormated)) {
							this.getMultiTextInputErrorIndex(uid) !== -1
								? (this.multipleTextInputError[this.getMultiTextInputErrorIndex(uid)] = {
										controlId: uid,
										msg: this.STranslate.instant(
											"edit-profil.errors.invalid.multiple_text-content-exist"
										)
								  })
								: this.multipleTextInputError.push({
										controlId: uid,
										msg: this.STranslate.instant(
											"edit-profil.errors.invalid.multiple_text-content-exist"
										)
								  });
							return;
						} else if (valueFormated?.length > 70) {
							this.multipleTextInputError[this.getMultiTextInputErrorIndex(uid)] = {
								controlId: uid,
								msg: this.STranslate.instant("edit-profil.errors.invalid.multiple_text-too-long")
							};
							return;
						}

						this.getMultiTextInputErrorIndex(uid) !== -1
							? (this.multipleTextInputError[this.getMultiTextInputErrorIndex(uid)] = {
									controlId: uid,
									msg: ""
							  })
							: this.multipleTextInputError.push({
									controlId: uid,
									msg: ""
							  });

						array.push(valueFormated);
						(<HTMLInputElement>document.getElementById(controlName)).value = "";
					}
				}
			}
		}
	}

	/**
	 * Remove query array for all possible type
	 * @param item
	 * @param type
	 */
	removeQueryItem(
		customId: string,
		item: string | IEventUser | IDocument | ITrack | IDocument | ISchedule,
		type: number,
		lang
	) {
		const selectedDatas = this.multipleTextValues;
		// string type (for multiple text input)
		selectedDatas[this.getMultiTextInputIndex(customId)].multiLanguageTextArray[lang].splice(
			selectedDatas[this.getMultiTextInputIndex(customId)].multiLanguageTextArray[lang].indexOf(item as string),
			1
		);
	}

	reinitializeInputStatus($event) {
		$event.target.markAsUntouched;
		const control = $event.target as UntypedFormControl;
		control.markAsUntouched;
	}

	/**
	 * Retuns the index of the item in the multipleTextValues array
	 * depending on the given customId parameter
	 * @param customId
	 * @returns number
	 */
	getMultiTextInputIndex(customId: string) {
		return this.multipleTextValues.findIndex((obj) => obj.uid === customId);
	}

	/**
	 * Retuns the index of the item in the multipleTextInputError array
	 * depending on the given customId parameter
	 * @param customId
	 * @returns number
	 */
	getMultiTextInputErrorIndex(customId: string) {
		return this.multipleTextInputError.findIndex((obj) => obj.controlId === customId);
	}

	/**
	 * getValidators
	 * @param item
	 * @returns
	 */
	getValidators(item: IRegisterFormItem) {
		const minCharValidator = Validators.minLength(this.SForms.minChar1);
		const passwordMinCharValidator = Validators.minLength(this.SForms.minChar6);
		const maxCharValidator = Validators.maxLength(this.SForms.maxChar100);
		const required = Validators.required;
		const emailValidator = Validators.email;
		const urlValidator = Validators.pattern(this.SForms.urlRegexString);

		if (item.type === FormItemType.INPUT) {
			switch (item.inputFieldType) {
				case TypeCustomFields.TEXT:
					return item.required
						? [required, minCharValidator, maxCharValidator]
						: [minCharValidator, maxCharValidator];

				case TypeCustomFields.EMAIL:
					return item.required
						? [required, minCharValidator, maxCharValidator, emailValidator]
						: [minCharValidator, maxCharValidator, emailValidator];

				case TypeCustomFields.URL:
					return item.required
						? [required, minCharValidator, maxCharValidator, urlValidator]
						: [minCharValidator, maxCharValidator, urlValidator];

				case TypeCustomFields.DATE: {
					const rules = [];
					if (item.required) {
						rules.push(required);
					}

					const cusSettings = (item.value as IFullCustomField)?.baseSettings;
					if (cusSettings?.enableDateRule) {
						rules;
						cusSettings.dateRule.minDayDateIsEnabled
							? rules.push(this.SForms.customMinDateValidator(DateTime.now().toISO()))
							: rules.push(this.SForms.customMinDateValidator(cusSettings.dateRule?.minDate));

						cusSettings.dateRule.maxDayDateIsEnabled
							? rules.push(this.SForms.customMaxDateValidator(DateTime.now().toISO()))
							: rules.push(this.SForms.customMaxDateValidator(cusSettings.dateRule?.maxDate));
					}

					return rules;
				}

				case TypeCustomFields.CHECKBOX: {
					return item.required ? [this.SForms.checkBoxValidator] : [];
				}

				case TypeCustomFields.PHONE:
					return item.required ? [required] : [];

				case TypeCustomFields.PASSWORD:
					return [required, passwordMinCharValidator, maxCharValidator];

				case TypeCustomFields.MULTI_TEXT:
					return item.required
						? [
								this.SForms.multiTextValidator(
									this.multipleTextValues.find(
										(val) => (item.value as IFullCustomField).baseSettings.uid === val.uid
									),
									true,
									this.currentLanguage
								),
								maxCharValidator
						  ]
						: [
								this.SForms.multiTextValidator(
									this.multipleTextValues.find(
										(val) => (item.value as IFullCustomField).baseSettings.uid === val.uid
									),
									false,
									this.currentLanguage
								),
								maxCharValidator
						  ];

				case TypeCustomFields.IMAGE: {
					if (item.isBaseField) {
						if (item.required) {
							this.mode === "register-modal" || !this.eventUser
								? (this.isProfilPictureValid = false)
								: this.eventUser?.photoUrl
								? (this.isProfilPictureValid = true)
								: (this.isProfilPictureValid = false);
						} else {
							this.isProfilPictureValid = true;
						}

						return [];
					} else {
						return item.required ? [required] : [];
					}
				}

				default:
					return item.required ? [required] : [];
			}
		} else {
			return [];
		}
	}

	/**
	 * loadCustomFieldFileImage
	 * @param event
	 * @param customField
	 */
	loadCustomFieldFileImage(event: any, customField: IFullCustomField, sectionId?: string) {
		const item: File = event.target.files[0];

		if (item.size > this.MAX_FILE_SIZE) {
			this.invalidSizeImg = true;
			return;
		}

		this.invalidSizeImg = false;
		this.actualFiles.push({
			customFieldId: customField.baseSettings.uid,
			data: item
		});

		this.customFieldMediaItems.find((media) => media.uid === customField.baseSettings.uid).data = item;

		this.initFileOrImageData(customField);

		const urlCreator = window.URL || window.webkitURL;
		customField.baseSettings.type === TypeCustomFields.FILE
			? (customField.fieldDatas.field.file.url = urlCreator.createObjectURL(item))
			: (customField.fieldDatas.field.image.url = urlCreator.createObjectURL(item));

		if (customField.baseSettings.type === TypeCustomFields.FILE) {
			customField.fieldDatas.field.file.name =
				item.name?.toLocaleLowerCase() === "image.jpg"
					? customField.baseSettings.name[this.event.language]
					: item.name;
			customField.fieldDatas.field.file.lastModified = Date.now();
			customField.fieldDatas.field.file.lastModifiedDate = DateTime.fromMillis(Date.now()).toString();
			customField.fieldDatas.field.file.type = item.type;
			customField.fieldDatas.field.file.size = item.size;
		} else {
			// IMAGE
			customField.fieldDatas.field.image.name =
				item.name?.toLocaleLowerCase() === "image.jpg"
					? customField.baseSettings.name[this.event.language]
					: item.name;
			customField.fieldDatas.field.image.lastModified = Date.now();
			customField.fieldDatas.field.image.lastModifiedDate = DateTime.fromMillis(Date.now()).toString();
			customField.fieldDatas.field.image.format = item.type;
			customField.fieldDatas.field.image.size = item.size;
			customField.fieldDatas.field.image.isExternalUrl = false;

			// Update control field content
			try {
				// prevent for set vlaue to file input errors type
				this.form
					.get(sectionId)
					.get(customField.baseSettings.uid + "_URL")
					?.patchValue(
						item.name?.toLocaleLowerCase()?.includes("image")
							? customField.baseSettings.name[this.event.language]
							: item.name
					);
			} catch (error) {}
		}
	}

	/**
	 * getCorrespondingItemFileOrImage
	 */
	getCorrespondingItemFileOrImage(uid: string) {
		return this.customFieldMediaItems.find((item) => item.uid === uid)?.data;
	}

	/**
	 * initFileOrImageData
	 */
	initFileOrImageData(customField: IFullCustomField) {
		if (customField.baseSettings.type === TypeCustomFields.FILE) {
			customField.fieldDatas = {
				uid: customField.baseSettings.uid,
				field: {
					file: {
						lastModified: null,
						lastModifiedDate: null,
						name: null,
						size: null,
						type: null,
						url: null
					} as IFile
				} as IField
			};
		} else if (customField.baseSettings.type === TypeCustomFields.IMAGE) {
			customField.fieldDatas = {
				uid: customField.baseSettings.uid,
				field: {
					image: {
						format: null,
						lastModified: null,
						lastModifiedDate: null,
						name: null,
						size: null,
						url: null,
						isExternalUrl: false
					} as IImage
				} as IField
			};
		}
	}

	/**
	 * deleteCustomFieldFieldImage
	 * @description delete custom field input file
	 * @param customField
	 */
	deleteCustomFieldFileOrImage(customField: IFullCustomField, sectionId: string) {
		this.customFieldMediaItems.find((media) => media.uid === customField.baseSettings.uid).data = null;

		if (customField.baseSettings.type === TypeCustomFields.FILE) {
			customField.fieldDatas.field.file = {
				lastModified: null,
				lastModifiedDate: null,
				name: null,
				type: null,
				url: null,
				size: null
			};

			// clear control
			try {
				// prevent for set value to file input errors type
				this.form.get(sectionId).get(`${customField.baseSettings.uid}`)?.reset("", { emitEvent: false });
			} catch (error) {
				console.error("🚀 ~ CustomFieldsEditComponent ~ resetTempOptionCount ~ error", error);
			}
		} else {
			customField.fieldDatas.field.image = {
				lastModified: null,
				format: null,
				lastModifiedDate: null,
				name: null,
				url: null,
				size: null,
				isExternalUrl: false
			};

			// clear control
			try {
				// prevent for set vlaue to file input errors type
				this.form.get(sectionId).get(`${customField.baseSettings.uid}_URL`)?.reset();
				this.form.get(sectionId).get(`${customField.baseSettings.uid}`)?.reset();
			} catch (error) {
				console.error("🚀 ~ CustomFieldsEditComponent ~ resetTempOptionCount ~ error", error);
			}

			this.isValidUrl && (this.isValidUrl = false);
		}
	}

	/**
	 * openDocumentOrImage
	 * @param document
	 */
	async openDocumentOrImage(item: IFile | IImage) {
		try {
			await Browser.open({
				url: item.url,
				windowName: item.name,
				presentationStyle: "popover"
			});
		} catch (error) {
			this.SUtility.presentToast(
				this.STranslate.instant("errors.error.error-open-document"),
				3000,
				"bottom",
				"danger"
			);
		}
	}

	/**
	 * importCustomFieldFileImage
	 * @description Import file or image from user computer and store it on the storage
	 * @param event
	 * @param customField
	 */
	importCustomFieldFileImage(eventUser: IEventUser, customField: IFullCustomField) {
		const oldCustomFieldData: ICustomFieldData = { field: {}, uid: "" };

		Object.assign(
			oldCustomFieldData,
			eventUser.customFields.find((item) => item.uid === customField.baseSettings.uid)
		);

		const item = this.customFieldMediaItems.find((item) => item.uid === customField.baseSettings.uid)?.data;

		if (!item) return of(null);

		const path = `/events/${this.event.uid}/modules/${this.module.uid}/event-users/${eventUser.uid}/custom-fields/${customField.baseSettings.uid}`;

		return from(this.storage.checkIfFileExists(path)).pipe(
			switchMap(async (fileExist) => {
				try {
					if (fileExist) {
						const pathToDelete = `/events/${this.event.uid}/modules/${this.module.uid}/event-users/${eventUser.uid}/custom-fields/${customField.baseSettings.uid}`;
						await this.storage.deleteDocumentOnStorage(pathToDelete);
					}
					const url = await this.storage.uploadFile(
						item,
						path,
						customField.baseSettings.type === TypeCustomFields.FILE ? item.type : "image/png"
					);

					return url;
				} catch (error) {
					console.error("🚀 ~ CustomFieldsEditComponent ~ switchMap ~ error:", error);
				}
			})
		);
	}

	/**
	 * getImageByUrl
	 * @param event
	 * @param customField
	 */
	getImageByUrl(event: any, customField: IFullCustomField, sectionId: string) {
		const imageUrl = this.form
			.get(sectionId)
			.get(customField.baseSettings.uid + "_URL")
			.value?.toString();
		const urlRegex = /(https?:\/\/.*\.(?:png|jpg))/gm;
		if (urlRegex.test(imageUrl)) {
			const urlSplited = imageUrl.split("/");
			const imageName: string = imageUrl.split("/")[urlSplited.length - 1];
			const formatImage: string = imageName ? imageName.split(".")[1] : null;

			this.initFileOrImageData(customField);

			customField.fieldDatas.field.image.name = imageName;
			customField.fieldDatas.field.image.lastModified = Date.now();
			customField.fieldDatas.field.image.lastModifiedDate = DateTime.fromMillis(Date.now()).toString();
			customField.fieldDatas.field.image.format = formatImage;
			customField.fieldDatas.field.image.size = 0;
			customField.fieldDatas.field.image.url = imageUrl;
			customField.fieldDatas.field.image.isExternalUrl = true;

			this.isValidUrl = true;
		} else {
			this.isValidUrl = false;
		}
	}

	/**
	 * showCoutryModal
	 * @param customField
	 * @param dividerId
	 */
	async showCoutryModal(customField: IFullCustomField, sectionId: string) {
		const sectionIndex = this.sections.findIndex((item) => item.uid === sectionId);
		if (sectionIndex === -1) return;
		const itemIndex = this.sections[sectionIndex].items.findIndex(
			(item) => item.uid === customField.baseSettings.uid
		);
		if (itemIndex === -1) return;

		try {
			const modal = await this.modalCtrl.create({
				component: PathComponents.coutryListModal,
				componentProps: {
					eventId: this.event.uid
				},
				cssClass: this.isMobile ? "full-sized-modal" : "confirm-register-modal"
			});
			await modal.present();

			const modalData: any = await modal.onWillDismiss();

			(this.sections[sectionIndex].items[itemIndex].value as IFullCustomField).fieldDatas = {
				uid: customField.baseSettings.uid,
				field: {
					country: {
						name: "",
						dialCode: "",
						code: "",
						flag: ""
					} as ICountry
				}
			};

			if (modalData && modalData.data) {
				(this.sections[sectionIndex].items[itemIndex].value as IFullCustomField).fieldDatas.field.country = {
					...modalData.data.data
				};

				// update corresponding form control

				try {
					// prevent for set vlaue to file input errors type
					this.form
						.get(sectionId)
						.get(`${customField.baseSettings.uid}`)
						.setValue(
							(this.sections[sectionIndex].items[itemIndex].value as IFullCustomField).fieldDatas.field
								.country
								? (this.sections[sectionIndex].items[itemIndex].value as IFullCustomField).fieldDatas
										.field.country?.name
								: ""
						);
				} catch (error) {
					console.error("🚀 ~ CustomFieldsEditComponent ~ resetTempOptionCount ~ error", error);
				}
			}
		} catch (error) {
			this.snackbar.open(this.STranslate.instant("snackbar.error_occured"), "", {
				duration: 3000,
				panelClass: "error-snackbar"
			});
		}
	}

	/**
	 * onValidate
	 */
	onValidate() {
		// Check if there's not null value for all form fields value
		Object.values(this.form.controls).forEach((control) => {
			if (!control.value) {
				this.snackbar.open(this.STranslate.instant("snackbar.error_occured"), "", {
					duration: 3000,
					panelClass: "error-snackbar"
				});
			}
		});

		this.stepIndexes.push(this.actualStep);

		this.mode === "accompanying-form-modal"
			? (this.registerForm.formContent.accompanyingSections = this.sections)
			: (this.registerForm.formContent.sections = this.sections);

		this.validateFields.emit({
			formDatas: this.getFormsValues(),
			registerForm: this.registerForm
		});
	}

	/**
	 * getFormsValues
	 * @returns
	 */
	getFormsValues() {
		// Removes values in unused sections (if necessary)
		if (
			((this.mode === "register-modal" || this.mode === "accompanying-form-modal") &&
				this.actualStep === this.sections.length - 1) ||
			(this.mode !== "register-modal" && this.mode !== "accompanying-form-modal")
		) {
			this.sections.forEach((section, index) => {
				if (!this.stepIndexes.includes(index)) {
					section.items
						.filter((item) => !item.hidden && !item.isBaseField)
						.forEach((item) => {
							if (
								this.form
									.get(section.uid)
									.get(
										this.SForms.isMultiLanguageField(item)
											? `${item.uid}_${this.currentLanguage}`
											: item.uid
									) &&
								this.form
									.get(section.uid)
									.get(
										this.SForms.isMultiLanguageField(item)
											? `${item.uid}_${this.currentLanguage}`
											: item.uid
									)?.value !== null &&
								this.form
									.get(section.uid)
									.get(
										this.SForms.isMultiLanguageField(item)
											? `${item.uid}_${this.currentLanguage}`
											: item.uid
									)?.value !== ""
							) {
								this.form
									.get(section.uid)
									.get(
										this.SForms.isMultiLanguageField(item)
											? `${item.uid}_${this.currentLanguage}`
											: item.uid
									)
									.setValue(null);
							}
							// else if (
							// 	this.form
							// 		.get(section.uid)
							// 		.get(
							// 			this.SForms.isMultiLanguageField(item)
							// 				? `${item.uid}_${this.currentLanguage}`
							// 				: item.uid
							// 		) &&
							// 	this.form
							// 		.get(section.uid)
							// 		.get(
							// 			this.SForms.isMultiLanguageField(item)
							// 				? `${item.uid}_${this.currentLanguage}`
							// 				: item.uid
							// 		)?.value !== null &&
							// 	this.form
							// 		.get(section.uid)
							// 		.get(
							// 			this.SForms.isMultiLanguageField(item)
							// 				? `${item.uid}_${this.currentLanguage}`
							// 				: item.uid
							// 		)?.value !== ""
							// ) {
							// 	this.form.get(section.uid).get(item.uid).setValue(null);
							// }
						});
				}
			});
		}

		return this.form.getRawValue();
	}

	/**
	 * Open terms or privacy modal
	 * @param type
	 * @returns
	 */
	async openModalTermsOrPrivacy(type: string) {
		const modal = await this.modalCtrl.create({
			component: type === "privacy" ? PathComponents.privacy : PathComponents.termsOfUse,
			componentProps:
				type === "privacy"
					? {
							privacy: null,
							event: this.event
					  }
					: {
							terms: null,
							event: this.event
					  }
		});
		return await modal.present();
	}

	/**
	 * eraseCountryInput
	 * @param event
	 * @param sectionId
	 * @param itemId
	 */
	eraseCountryInput(event, sectionId: string, itemId: string) {
		try {
			// prevent for set vlaue to file input errors type
			this.form.get(sectionId).get(itemId)?.setValue("");
		} catch (error) {}
	}

	/**
	 * getPicture
	 */

	async promptCameraModal(sectionId: string, itemId: string) {
		const item = this.sections
			.find((section) => section.uid === sectionId)
			.items.find((item) => item.uid === itemId);
		try {
			const image = await this.storage.getPictureUri();
			this.actualImage = image;
			this.isProfilPictureValid = item.required ? this.actualImage : true;
		} catch (error) {
			if (!error.toString().includes("cancelled"))
				this.snackbar.open(this.STranslate.instant("snackbar.error_occured"), "", {
					duration: 3000,
					panelClass: "error-snackbar"
				});
		}
	}

	deleteProfilPic(item: IRegisterFormItem) {
		try {
			if (this.eventUser && this.eventUser?.photoUrl) {
				this.eventUser.photoUrl = null;
			}

			this.actualImage = null;

			this.isProfilPictureValid = item.required ? this.actualImage : true;
		} catch (error) {}
	}

	/**
	 * saveProfilPicture
	 * @description save the permanent profil picture to the storage
	 * and update the picture url in the user profile document used on 'profile page'
	 * @Returns Promise<string>
	 */
	async saveProfilPicture(eventUser: IEventUser) {
		if (eventUser) {
			this.eventUser = eventUser;
		}
		if (!this.actualImage) return;
		const photoBlob = await this.storage.getBlobFromPhotoUri(this.actualImage.webPath);

		return new Promise<string>((resolve, reject) => {
			const path = this.eventUser
				? // eslint-disable-next-line max-len
				  `/events/${this.event.uid}/modules/${
						eventUser ? eventUser.moduleId : this.eventUser?.moduleId
				  }/event-users/${eventUser ? eventUser.uid : this.eventUser?.uid}/${
						eventUser ? eventUser.uid : this.eventUser?.uid
				  }.${this.actualImage.format}`
				: null;

			if (!path) reject("No path!");

			this.storage
				.uploadFile(photoBlob, path, photoBlob && photoBlob.type ? photoBlob.type : "image/png")
				.then((url) => {
					// update user document
					this.SFirestore.updateDocument(
						`events/${this.event.uid}/modules/${this.module.uid}/event-users/${
							eventUser ? eventUser.uid : this.eventUser.uid
						}`,
						{ photoUrl: url }
					);
					this.actualImage = null;
					resolve(url);
				})
				.catch((error) => {
					reject(error);
				});
		});
	}

	/**
	 * getFullOrPartOfComputedCustomField
	 * @param uid
	 * @param typeCus
	 * @returns
	 */
	getFullOrPartOfComputedCustomField(
		uid: string,
		typeCus: "module" | "base" | "both"
	): IModuleCustomField | ICustomField | IFullCustomField {
		const returnValue =
			typeCus === "module"
				? this.computedCustomFields.find((cus) => cus.baseSettings.uid === uid)?.moduleSettings
				: typeCus === "base"
				? this.computedCustomFields.find((cus) => cus.baseSettings.uid === uid)?.baseSettings
				: this.computedCustomFields.find((cus) => cus.baseSettings.uid === uid);

		return returnValue || null;
	}

	getRegisteredModules() {
		this.subscriptions.push(
			this.store.select(getModulesByTypes([TypeModule.SCHEDULE])).subscribe((modules) => {
				if (!_.isEqual(modules, this.toRegisterModules)) {
					this.toRegisterModules = modules;
				}
			})
		);
	}

	/**
	 * getSpecificModule
	 */
	getSpecificModule(moduleId: string) {
		return this.toRegisterModules.find((module) => module.uid === moduleId);
	}

	getSpecificItemsOfModule(moduleId: string, type: number) {
		if (type === TypeModule.SCHEDULE) {
			return this.sessions
				.filter((session) => session.moduleId === moduleId)
				.sort((a, b) => (a.startDate > b.startDate ? 1 : a.startDate < b.startDate ? -1 : 0));
		} else {
			return [];
		}
	}

	/**
	 * checkValueOfItem
	 * @param item
	 * @param schedule
	 * @param type
	 * @returns
	 */
	checkValueOfItem(item: IRegisterFormItem, schedule: ISchedule, type: number) {
		if (type === TypeModule.SCHEDULE) {
			return this.registeredDatasForField
				.find((fieldReg) => fieldReg.moduleId === (item.value as IModule)?.uid)
				?.sessions.find((session) => session.uid === schedule.uid)
				? true
				: false;
		} else {
			return false;
		}
	}

	/**
	 * registerOrUnregisterToItem
	 * @param item
	 * @param schedule
	 * @param type
	 * @param value
	 * @returns
	 */
	registerOrUnregisterToItem(item: IRegisterFormItem, schedule: ISchedule, type: number, value: any) {
		if (type === TypeModule.SCHEDULE) {
			const registeredSession = this.registeredDatasForField
				.find((fieldReg) => fieldReg.moduleId === (item.value as IModule)?.uid)
				.sessions.find((session) => session.uid === schedule.uid);
			if (
				value &&
				value.detail &&
				value.detail.checked &&
				!registeredSession &&
				this.registeredDatasForField.find((fieldReg) => fieldReg.moduleId === (item.value as IModule)?.uid) &&
				this.registeredDatasForField.find((fieldReg) => fieldReg.moduleId === (item.value as IModule)?.uid)
					.sessions.length < item.maxSelections
			) {
				this.registeredDatasForField
					.find((fieldReg) => fieldReg.moduleId === (item.value as IModule)?.uid)
					.sessions.push(schedule);
			} else if (value && value.detail && !value.detail.checked && registeredSession) {
				this.registeredDatasForField.find(
					(fieldReg) => fieldReg.moduleId === (item.value as IModule)?.uid
				).sessions = this.registeredDatasForField
					.find((fieldReg) => fieldReg.moduleId === (item.value as IModule)?.uid)
					.sessions.filter((session) => session.uid !== schedule.uid);
			}
		} else {
			return;
		}
	}

	/**
	 * getRegisteredDatasForField
	 * @returns
	 */
	getRegisteredDatasForField() {
		return this.registeredDatasForField;
	}

	/**
	 * checkRegisterCheckboxDisable
	 * @param item
	 * @param schedule
	 * @param type
	 * @returns
	 */
	checkRegisterCheckboxDisable(item: IRegisterFormItem, schedule: ISchedule, type: number) {
		if (type === TypeModule.SCHEDULE) {
			const registeredDatasField = this.registeredDatasForField.find(
				(fieldReg) => fieldReg.moduleId === (item.value as IModule)?.uid
			);

			if (
				(registeredDatasField &&
					registeredDatasField.sessions.length < item.maxSelections &&
					(_.uniq(schedule.registeredUsers).length < schedule.attendeesLimit ||
						Number(schedule.attendeesLimit) === -1)) ||
				(registeredDatasField && registeredDatasField.sessions.find((session) => session.uid === item.uid))
			) {
				return false;
			} else {
				return true;
			}
		} else {
			return false;
		}
	}

	/**
	 * canNotSubmitForm
	 * returns boolean
	 */
	canNotSubmitForm(): boolean {
		let result =
			(!this.isProfilPictureValid && this.isProfilPictureValid !== undefined) ||
			this.loader ||
			this.lockNextButton ||
			(this.registerForm.formSettings.enableCaptcha && !this.recaptchaStatus);
		const currentSection = this.sections[this.actualStep];
		const validStep = this.form.get(currentSection.uid).valid;

		switch (this.mode) {
			case "register-modal":
				result =
					result ||
					(this.actualStep === this.sections.length - 1 && !this.validPassword) ||
					!this.validPassword;
				break;
			case "accompanying-form-modal":
				return (
					result || (this.sections.length === 1 && !this.form.valid) || (!this.nextSectionId && !validStep)
				);

			case "fill-form-modal":
				result =
					result || (this.sections.length === 1 && !this.form.valid) || (!this.nextSectionId && !validStep);
				break;

			case "checkin":
				return (
					result || (this.sections.length === 1 && !this.form.valid) || (!this.nextSectionId && !validStep)
				);
		}

		return result;
	}

	/**
	 * Recover password
	 * @param email
	 */
	async recoveryPassword() {
		try {
			const email: string = this.form.get("initialSection").get(`${"email"}`).getRawValue() as string;
			await firstValueFrom(this.SAuth.recoveryPassword(email));
			this.snackbar.open(this.STranslate.instant("login.recovery_success"), "", {
				duration: 3000,
				panelClass: "success-snackbar"
			});
		} catch (error) {
			this.snackbar.open(this.STranslate.instant("login.recovery_fail"), "", {
				duration: 3000,
				panelClass: "error-snackbar"
			});
		}
	}

	/**
	 * canDisableOptionOptionLimit
	 * @param custom
	 * @param index
	 */
	canDisableOptionOptionLimit(computedCusId: string, index: number): boolean {
		const baseCus = this.getFullOrPartOfComputedCustomField(computedCusId, "base");

		if (!baseCus) return false;

		return (
			this.realTimeCountValues?.[computedCusId]?.["datas"]?.["countValues"]?.[index]?.[
				`${this.event.language}_Limit_${index}`
			] +
				this.realTimeCountValues?.[computedCusId]?.["datas"]?.["tempCountValues"]?.[index]?.[
					`${this.event.language}_Limit_${index}`
				] <
			(baseCus as ICustomField).optionsLimit[index][`${this.event.language}_Limit_${index}`]
		);
	}

	/**
	 * onCustomFieldOptionChange
	 * @param custom
	 * @param event
	 */
	onCustomFieldOptionChange(custom: IFullCustomField, event) {
		// set value of the checked option in all disponibles languages "languageSettings"
		if (custom.baseSettings.type === TypeCustomFields.SELECT && event.target.value) {
			const indexOption = custom.baseSettings.options.findIndex(
				(option) => option[this.currentLanguage] === event.target.value[this.currentLanguage]
			);
			for (const langSetting of Object.entries(this.event.languagesSettings)) {
				if (indexOption !== -1 && langSetting[1]) {
					this.form
						.get(
							this.sections.find((section) =>
								section.items.find((item) => item.uid === custom.baseSettings.uid)
							).uid
						)
						.get(custom.baseSettings.uid + "_" + langSetting[0])
						?.patchValue(custom.baseSettings.options[indexOption]);
				}
			}
		} else {
			// do the same thing for multi-select who has array of options as value
			if (event.value) {
				const indexOptions: number[] = custom.baseSettings.options
					.filter((option) =>
						event.value.find((opt) => opt[this.currentLanguage] === option[this.currentLanguage])
					)
					.map((option) => custom.baseSettings.options.indexOf(option));

				for (const langSetting of Object.entries(this.event.languagesSettings)) {
					if (indexOptions.length > 0 && langSetting[1] && langSetting[0] !== this.currentLanguage) {
						this.form
							.get(
								this.sections.find((section) =>
									section.items.find((item) => item.uid === custom.baseSettings.uid)
								).uid
							)
							.get(custom.baseSettings.uid + "_" + langSetting[0])
							?.patchValue(
								custom.baseSettings.options
									.filter((option, i) => indexOptions.includes(i))
									.map((option) => option)
							);
					} else if (
						indexOptions.length === 0 &&
						event.value.length === 0 &&
						langSetting[1] &&
						langSetting[0] !== this.currentLanguage
					) {
						this.form
							.get(
								this.sections.find((section) =>
									section.items.find((item) => item.uid === custom.baseSettings.uid)
								).uid
							)
							.get(custom.baseSettings.uid + "_" + langSetting[0])
							?.patchValue(null);
					}
				}
			}
		}

		// Limit feature
		if (custom.baseSettings.type === TypeCustomFields.SELECT && custom.baseSettings.enableSelectLimit) {
			if (!this.eventUser) {
				const indexOption = _.cloneDeep(custom).baseSettings.options.findIndex(
					(option) => option[this.currentLanguage] === event.target.value[this.currentLanguage]
				);

				if (indexOption === -1) return;

				const indexOfOperation = this.changeOperation.findIndex((op) => op.uid === custom.baseSettings.uid);
				if (indexOfOperation === -1) return;

				if (
					this.changeOperation[indexOfOperation].operations[indexOption] !== "add" ||
					this.changeOperation[indexOfOperation].operations.every((op) => op === null)
				) {
					this.changeOperation[indexOfOperation].operations[indexOption] = "add";

					this.changeOperation[indexOfOperation].operations = this.changeOperation[
						indexOfOperation
					].operations.map((op, i) => {
						if (i !== indexOption && op === "add") {
							op = "remove";
						} else if (i !== indexOption && op === null) {
							op = "none";
						}
						return op;
					});

					// update "tempOptionCount" variable with firestore transaction  with new functions
					this.updateTempOptionCount(custom.baseSettings);
				}
			}
		}
	}

	/**
	 * updateModuleCustomFieldCount
	 */
	async updateModuleCustomFieldCount(customField: ICustomField) {
		const changeOperations = this.changeOperation.slice().find((ch) => ch.uid === customField.uid);

		if (!changeOperations || !changeOperations.operations) return;

		if (
			changeOperations.operations.filter((op) => {
				return (op && op?.includes("add")) || (op && op.includes("remove"));
			}).length > 0
		) {
			return this.SCustomFields.updateModuleCustomFieldCount(
				this.event.uid,
				this.module.uid,
				customField,
				this.currentLanguage,
				changeOperations.operations,
				"optionsCount"
			);
		}
	}

	/**
	 * updateTempOptionCount
	 * @param customField
	 */
	async updateTempOptionCount(customField: ICustomField) {
		const changeOperations = this.changeOperation.slice().find((ch) => ch.uid === customField.uid);
		try {
			if (!changeOperations) throw new Error("No changes operations found");

			if (changeOperations.operations.filter((op) => op.includes("add") || op.includes("remove")).length > 0) {
				await this.SCustomFields.updateModuleCustomFieldCount(
					this.event.uid,
					this.module.uid,
					customField,
					this.currentLanguage,
					changeOperations.operations,
					"tempOptionsCount"
				);

				changeOperations.operations.forEach((op, i) => {
					if (op === "remove") {
						changeOperations.operations[i] = "none";
					}
				});
			}
		} catch (error) {
			if (error.error.code === 409) {
				// get the associated control field and reset the value
				const sectionOfItem = this.sections.find((section) =>
					section.items.find((item) => item.uid === customField.uid)
				);

				// get name of the of base of indexOption
				let indexOption = null;
				changeOperations.operations.forEach((op, i) => {
					if (op === "add") {
						indexOption = i;
					}
				});

				const optionName = indexOption !== null ? customField.options[indexOption][this.currentLanguage] : null;

				this.form
					.get(sectionOfItem.uid)
					.get(customField.uid + "_" + this.currentLanguage)
					?.setValue(null);

				this.snackbar.open(
					this.STranslate.instant("snackbar.limit_avaible_place_exed", {
						field: customField.name[this.currentLanguage] + optionName ? `(${optionName})` : ""
					}),
					"",
					{
						duration: 10000,
						panelClass: "error-snackbar"
					}
				);
			}
		}
	}

	/**
	 * resetTempOptionCount
	 * @param customField
	 */
	async resetTempOptionCount(customField: ICustomField) {
		try {
			// reverse add with remove in the change operation
			const changeOperations = this.changeOperation.slice().find((ch) => ch.uid === customField.uid);

			if (!changeOperations) return;

			changeOperations.operations = changeOperations.operations.map((op) => {
				if (op === "add") {
					op = "remove";
				}

				return op;
			});

			if (
				changeOperations.operations &&
				changeOperations.operations?.filter((op) => op.includes("remove")).length > 0
			) {
				await this.SCustomFields.updateModuleCustomFieldCount(
					this.event.uid,
					this.module.uid,
					customField,
					this.currentLanguage,
					changeOperations.operations,
					"tempOptionsCount"
				);

				changeOperations.operations.forEach((op, i) => {
					if (op === "remove") {
						changeOperations.operations[i] = "none";
					}
				});
			}
		} catch (error) {}
	}

	/**
	 * getModuleTempOptionCusValues
	 * @returns
	 */
	getModuleTempOptionCusValues() {
		if (this.optionLimitSub && !this.optionLimitSub.closed) {
			this.optionLimitSub.unsubscribe();
		}

		const obsArray = this.computedCustomFields
			.filter((cus) => cus.baseSettings.type === TypeCustomFields.SELECT && cus.baseSettings.enableSelectLimit)
			.map((cus) => {
				return this.SFirestore.snapshotChangesDocument(
					`events/${this.event.uid}/modules/${this.module.uid}/custom-fields/${cus.baseSettings.uid}`
				).pipe(
					switchMap((doc) => {
						if (doc.exists) {
							const data = doc.data() as IModuleCustomField;
							return of({
								[cus.baseSettings.uid]: {
									datas: {
										countValues: data.optionsCount,
										tempCountValues: data.tempOptionsCount
									}
								}
							});
						}
					})
				);
			});

		this.optionLimitSub = combineLatest(obsArray).subscribe((datas) => {
			this.realTimeCountValues = datas.reduce((acc, curr) => {
				return { ...acc, ...curr };
			});
		});
	}

	/**
	 * shouldShowResetPasswordText
	 * @param currentSection
	 * @param mode
	 * @returns
	 */
	shouldShowResetPasswordText(
		currentSection: IRegisterFormSection,
		mode: "fill-form-modal" | "accompanying-form-modal" | "register-modal"
	): boolean {
		let result =
			this.messages &&
			this.messages?.warningMsg ===
				this.STranslate.instant("login.account_already_exist_with_email_public_event");
		switch (mode) {
			case "register-modal":
				result =
					result && (this.actualStep === this.sections.length - 1 || this.nextSectionId === "submitSection");
				break;

			case "fill-form-modal":
				result =
					result &&
					(this.actualStep === this.sections.length - 1 ||
						this.nextSectionId === "submitSection" ||
						currentSection.defaultNextSectionId === "submitSection");
				break;

			case "accompanying-form-modal":
				result = result && false;
				break;
		}

		return result;
	}

	/**
	 * onKeyupAddress
	 * @param itemId
	 * @param value
	 */
	onKeyupAddress(itemId: string, value: string) {
		this.placesPredictions.find((place) => place.controlId === itemId).searchValue$.next(value);
	}

	/**
	 * getPlacePredictionsById
	 */
	getPlacePredictionsById(uid: string) {
		return this.placesPredictions.find((place) => place.controlId === uid)?.data;
	}

	/**
	 * getCustomFieldAddressState
	 */
	getCustomFieldAddressState(uid: string): IPlacesPredictions {
		return this.placesPredictions.find((place) => place.controlId === uid);
	}

	/**
	 * getPlacesPrediction
	 */
	async getPlacePredictions(value, uid: string) {
		const correspondingCtrlIndex = this.placesPredictions.findIndex((place) => place.controlId === uid);
		try {
			if (correspondingCtrlIndex !== -1) {
				this.placesPredictions[correspondingCtrlIndex].loading = true;

				const data: any = await this.SCustomFields.getPlacePredictions(value);
				this.placesPredictions[correspondingCtrlIndex].data = data.result.predictions;
				this.placesPredictions[correspondingCtrlIndex].loading = false;
			}
		} catch (error) {
			this.placesPredictions[correspondingCtrlIndex].loading = false;
		}
	}

	displayFn(selectedValue: string | any): string {
		return typeof selectedValue === "string" ? selectedValue : selectedValue?.target?.textContext;
	}

	trackOptionsByFn(index: number, item: ILanguage): string {
		return item[this.currentLanguage];
	}

	compareWithFn(o1: any, o2: any) {
		if (o1 && o2) {
			return _.isEqual(o1, o2);
		}

		return false;
	}

	isOptionDisabled(sectionId: string, itemId: string, opt: any, limit: number): boolean {
		return (
			this.form.get(sectionId)?.get(itemId + "_" + this.currentLanguage).value &&
			this.form.get(sectionId)?.get(itemId + "_" + this.currentLanguage).value?.length >= limit &&
			!this.form
				.get(sectionId)
				?.get(itemId + "_" + this.currentLanguage)
				.value?.map((data) => data?.[this.currentLanguage])
				?.includes(opt[this.currentLanguage])
		);
	}

	/**
	 * generateRandomEmail
	 * @returns
	 */
	async generateRandomEmail() {
		try {
			this.emailButtonLoader = true;
			const randomName = Math.random().toString(36).substring(2, 11);
			`${randomName}@${this.registerForm.formSettings.defaultEmailsDomain}`;
			const regex = this.SForms.emailRegex;
			const generatedEmail =
				`${randomName}@${this.registerForm.formSettings.defaultEmailsDomain}`?.toLocaleLowerCase();

			if (regex.test(generatedEmail)) {
				// check if the email is already used
				const results: any[] = await firstValueFrom(
					combineLatest([
						this.SAuth.checkAuthAccountExist(generatedEmail, "", "email"),
						this.SAuth.getUserByEmail(generatedEmail),
						this.SEventUsers.getEventUserByEmail(generatedEmail, this.event.uid, "complete")
					])
				);

				if (results[0] || results[1] || results[2]) {
					return this.generateRandomEmail();
				} else {
					this.form.get("initialSection").get("email").setValue(generatedEmail);
					this.emailButtonLoader = false;
					return generatedEmail;
				}
			}
		} catch (error) {
			this.emailButtonLoader = false;
			console.error("🚀 error:", error);
		}
	}
}
