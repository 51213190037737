import {
	AfterViewChecked,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild,
	AfterViewInit,
	OnChanges
} from "@angular/core";
import { FormBuilder, UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AlertController, IonicSlides, Platform } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import * as _ from "lodash-es";
import { DateTime } from "luxon";
import { Subscription, firstValueFrom } from "rxjs";
import { take } from "rxjs/operators";
import { GetHeaderState, GetHeaderTitle, ResetHeaderState } from "src/app/shared/actions/utility.actions";
import { TypeHeader } from "src/app/shared/enums/type-header";
import { TypeModule } from "src/app/shared/enums/type-module";
import {
	IEvent,
	IEventUser,
	IModule,
	IPointRecord,
	IQuiz,
	IQuizQuestion,
	IQuizQuestionAnswer,
	IQuizQuestionResult
} from "src/app/shared/interfaces";
import { getCurrentEventUser } from "src/app/shared/selectors/auth.selectors";
import { getCurrentEvent } from "src/app/shared/selectors/events.selectors";
import { getSpecificQuiz } from "src/app/shared/selectors/interactivity.selectors";
import { getSpecificModule } from "src/app/shared/selectors/modules.selectors";
import { selectRouteNestedParams, selectUrl } from "src/app/shared/selectors/router.selectors";
import {
	AnalyticsService,
	FirestoreService,
	PointsService,
	QuizsService,
	UtilityService
} from "src/app/shared/services";
import { environment } from "src/environments/environment";
import { register } from "swiper/element/bundle";

@Component({
	selector: "app-quiz",
	templateUrl: "./quiz.component.html",
	styleUrls: ["./quiz.component.scss"]
})
export class QuizComponent implements OnInit, OnDestroy, AfterViewChecked, AfterViewInit, OnChanges {
	subscriptions: Subscription[] = [];
	langSub: Subscription;

	loader: boolean = true;
	init: boolean = false;

	@Input() componentMode: boolean = false;
	@Output() changeView: EventEmitter<{ segmentType: string; type: string; uid: string }> = new EventEmitter();

	@ViewChild("mySlider", { static: false }) mySlider: ElementRef;
	swiperModules = [IonicSlides];

	@Input() eventId: string;
	event: IEvent;
	@Input() moduleId: string;
	module: IModule;
	@Input()
	quizId: string;
	quiz: IQuiz;
	@Input() sessionId: string;
	eventUser: IEventUser;
	@Input() showBackBtn: boolean;

	currentQuestion: IQuizQuestion;

	questionsForm: UntypedFormGroup;

	interval: any;

	includeImgToAnswers: boolean = true;
	questionsNumberVisibility: boolean = true;

	startIndex: number = 0;
	indexSlide: number = 1;
	counter: number = 0;
	progress: number = 0;

	checkedView = false;
	totalUserAnswers: boolean = false;
	quizEnded: boolean = false;
	submitting: boolean = false;

	isMobile: boolean = false;
	currentLanguage: string = environment.platform.defaultLanguage;

	showLastPageResume: boolean = false;

	quizAnsweredInInit: boolean = false;

	constructor(
		private platform: Platform,
		private SAnalytics: AnalyticsService,
		private store: Store,
		private SFirestore: FirestoreService,
		private alertCtrl: AlertController,
		private SQuizs: QuizsService,
		private SPoints: PointsService,
		private STranslate: TranslateService,
		private SUtility: UtilityService,
		private fb: FormBuilder,
		private snackbar: MatSnackBar
	) {
		this.isMobile =
			(this.platform.is("mobile") && window.innerWidth < 768) ||
			this.platform.is("mobileweb") ||
			window.innerWidth < 768
				? true
				: false;
	}

	ngOnInit() {
		if (this.componentMode) {
			this.currentLanguage = this.STranslate.currentLang;
			this.subscriptions.push(
				this.STranslate.onLangChange.subscribe((lang) => {
					this.currentLanguage = lang.lang;
				})
			);
			this.initDatas();
		}
	}

	ngAfterViewInit() {
		register();
	}

	ionViewWillEnter() {
		if (!this.componentMode) {
			this.currentLanguage = this.STranslate.currentLang;
			if (this.langSub && !this.langSub.closed) {
				this.langSub.unsubscribe();
			}
			this.langSub = this.STranslate.onLangChange.subscribe((lang) => {
				this.currentLanguage = lang.lang;
			});
			this.store
				.select(selectUrl)
				.pipe(take(1))
				.subscribe(() => {
					this.store
						.select(selectRouteNestedParams)
						.pipe(take(1))
						.subscribe((params) => {
							this.eventId = params.eventId;
							this.moduleId = params.moduleId;
							this.quizId = params.quizId;
							this.subscriptions.forEach((sub) => sub.unsubscribe());
							// Analytics
							this.SAnalytics.quizAccess(this.eventId, this.moduleId, this.quizId);
							this.initDatas();
						});
				});
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes && changes.quizId && !changes.quizId.firstChange) {
			this.subscriptions.forEach((sub) => sub?.unsubscribe());
			this.event = null;
			this.module = null;
			this.quiz = null;
			this.eventUser = null;
			this.initDatas();
		}
	}

	initDatas() {
		this.getEvent();
		this.getModule();
		this.getEventUser();
		this.getQuiz();
	}

	ionViewWillLeave() {
		if (!this.componentMode) {
			this.quiz = null;
			this.store.dispatch(ResetHeaderState(null));
		}
		this.subscriptions.forEach((sub) => sub.unsubscribe());

		this.indexSlide = 1;
		this.counter = 0;
		this.progress = 0;
		this.quizAnsweredInInit = false;
		this.showLastPageResume = false;
		clearInterval(this.interval);
	}

	/**
	 * Unsubscribe all subscriptions
	 */
	ngOnDestroy() {
		this.subscriptions.concat([this.langSub]).forEach((sub) => sub?.unsubscribe());
	}

	ngAfterViewChecked() {
		if (!this.checkedView) {
			if (this.mySlider !== undefined) {
				this.mySlider.nativeElement.swiper.slideTo(this.startIndex - 1);
				this.mySlider.nativeElement.swiper.disable();
				this.checkedView = true;
			}
		}
	}

	/**
	 * Get event
	 */
	getEvent() {
		this.subscriptions.push(
			this.store.select(getCurrentEvent).subscribe((event) => {
				if (!_.isEqual(this.event, event)) {
					this.event = event;
				}
			})
		);
	}

	/**
	 * Getting module
	 */
	getModule() {
		this.subscriptions.push(
			this.store.select(getSpecificModule(this.moduleId)).subscribe((module) => {
				if (!_.isEqual(this.module, module)) {
					this.module = module;
				}
			})
		);
	}

	/**
	 * Get quiz
	 */
	getQuiz() {
		this.init = false;
		this.quiz = null;
		this.subscriptions.push(
			this.store.select(getSpecificQuiz(this.quizId)).subscribe(async (quiz) => {
				if (!_.isEqual(this.quiz, quiz)) {
					this.quiz = quiz;
					if (this.quiz) {
						this.quiz.questions = this.quiz.questions.filter((question) => question.visibility);
					}
					if (
						!this.quiz ||
						(this.quiz && !this.quiz.visibility) ||
						(this.quiz && this.quiz.questions.length === 0)
					) {
						this.goBackToList();
						return;
					}
					if (this.quiz) {
						this.patchResultsForm();
						if (!this.init) {
							const notAnsweredYet = this.quiz.questions.filter((question) =>
								this.quiz.eventUserResults.every((result) => result.questionId !== question.uid)
							);
							// if (this.quiz.viewAnswered) {
							// } else {
							if (
								this.quiz.eventUserResults.length > 0 &&
								this.quiz.eventUserResults.length !== this.quiz.questions.length
							) {
								this.currentQuestion = { ...notAnsweredYet[0] };
								this.startIndex =
									this.quiz.questions.findIndex(
										(question) => question.uid === this.currentQuestion.uid
									) + 1;
								this.indexSlide = this.startIndex;
							} else if (
								this.quiz.eventUserResults.length > 0 &&
								this.quiz.eventUserResults.length === this.quiz.questions.length
							) {
								this.quizAnsweredInInit = true;
								if (this.quiz.viewAnswered) {
									this.currentQuestion =
										this.quiz.questions.length > 0 ? { ...this.quiz.questions[0] } : null;
								} else {
									// Quiz already full answered
									this.showLastPageResume = true;
									this.currentQuestion =
										this.quiz.questions.length > 0
											? this.quiz.questions[this.quiz.questions.length - 1]
											: null;
								}
							} else {
								this.currentQuestion =
									this.quiz.questions.length > 0 ? { ...this.quiz.questions[0] } : null;
							}
							// }
						}
						this.progressBar();

						this.init = true;
						this.startCountdown();
					}
				}

				if (this.quiz) {
					const selectedUrl = await firstValueFrom(this.store.select(selectUrl));
					if (!this.componentMode) {
						this.store.dispatch(
							GetHeaderState({
								payload: {
									item: this.quiz,
									title: this.quiz.name,
									type: TypeHeader.QUIZ
								}
							})
						);
					} else if (!selectedUrl.includes("session")) {
						this.store.dispatch(GetHeaderTitle({ payload: this.quiz.name }));
					}
				}
			})
		);
	}

	/**
	 * Get event user
	 */
	getEventUser() {
		this.subscriptions.push(
			this.store.select(getCurrentEventUser).subscribe((eventUser) => {
				if (!_.isEqual(this.eventUser, eventUser)) {
					this.eventUser = eventUser;
				}
			})
		);
	}

	/**
	 * Patch form with all quiz result
	 */
	patchResultsForm() {
		this.questionsForm = this.fb.group({});
		this.quiz.questions.forEach((question) => {
			const resultOfQuestion = this.getResultOfQuestion(question);

			if (question.type === "oneSelect") {
				this.questionsForm.addControl(
					question.uid,
					new UntypedFormControl({
						value: resultOfQuestion ? resultOfQuestion.oneSelect.answerId : "",
						disabled: !this.checkCanChangeAnswer(question)
					})
				);
			} else if (question.type === "multipleSelect") {
				this.questionsForm.addControl(
					question.uid,
					new UntypedFormControl({
						value: resultOfQuestion ? resultOfQuestion.multipleSelect.answersIds : [],
						disabled: !this.checkCanChangeAnswer(question)
					})
				);
			}
		});
	}

	/**
	 * Check if can change answer
	 */
	checkCanChangeAnswer(question: IQuizQuestion) {
		const result = this.getResultOfQuestion(question);
		return result && !this.quiz.changeAnswers ? false : true;
	}

	/**
	 * Check if an answer is correct
	 * @param question
	 * @param answer
	 */
	checkAnswered(question: IQuizQuestion, answer: IQuizQuestionAnswer) {
		const hasOneCorrectAnswer = question.answers.some((answer) => answer.correct);
		const result = this.getResultOfQuestion(question);
		if (question.type === "oneSelect") {
			return hasOneCorrectAnswer &&
				result &&
				((result.oneSelect && result.oneSelect.answerId === answer.uid) || answer.correct)
				? true
				: false;
		} else {
			return hasOneCorrectAnswer &&
				result &&
				((result.multipleSelect && result.multipleSelect.answersIds.includes(answer.uid)) || answer.correct)
				? true
				: false;
		}
	}

	/**
	 * Check if a question is answered or not
	 * @param question
	 */
	isAnswered(question: IQuizQuestion) {
		// Check if a result exist or if answered in form
		return this.getResultOfQuestion(question) ||
			(question.type === "oneSelect" && this.questionsForm.get(question.uid).value) ||
			(question.type === "multipleSelect" && this.questionsForm.get(question.uid).value.length > 0)
			? true
			: false;
	}

	/**
	 * Get result of question
	 * @param question
	 * @returns
	 */
	getResultOfQuestion(question: IQuizQuestion) {
		return this.quiz.eventUserResults.find((result) => result.questionId === question.uid);
	}

	/**
	 * CHeck checkbox for multiple select
	 * @param question
	 */
	checkCheckbox(question: IQuizQuestion, answer: IQuizQuestionAnswer) {
		const answersIds = this.questionsForm.get(question.uid).value;
		return answersIds.includes(answer.uid) ? true : false;
	}

	/**
	 * Update checkbox
	 * @param question
	 * @param answer
	 * @param event
	 */
	updateCheckboxs(question: IQuizQuestion, answer: IQuizQuestionAnswer, event: any) {
		let answersIds = [...this.questionsForm.get(question.uid).value];
		if (event.detail.checked) {
			if (!answersIds.includes(answer.uid)) {
				answersIds.push(answer.uid);
			}
		} else {
			if (answersIds.includes(answer.uid)) {
				answersIds = answersIds.filter((uid) => uid !== answer.uid);
			}
		}
		this.questionsForm.get(question.uid).patchValue(answersIds);
	}

	/**
	 * Check if quiz is full answered
	 * @returns
	 */
	checkQuizAnswered() {
		let check: boolean = true;
		this.quiz.questions
			.filter((quiz) => quiz.type !== "plainText")
			.forEach((question) => {
				const result = this.getResultOfQuestion(question);
				if (!result) {
					check = false;
				}
			});
		return check;
	}

	/**
	 * Progress bar of quiz
	 */
	progressBar() {
		const activeIndex = this.indexSlide - 1;
		const totalSlides = this.quiz.questions.filter((question) => question.visibility).length;

		const aux = activeIndex * (100 / totalSlides);
		this.progress = Number(aux.toFixed(2));
	}

	/**
	 * Start countdown of quiz
	 */
	startCountdown() {
		if (this.quiz.activateTimer) {
			this.counter = this.quiz.timerSeconds;
			clearInterval(this.interval);

			this.interval = setInterval(() => {
				this.counter--;

				if (this.counter < 0 && this.counter !== null) {
					this.slideNext();
				}
			}, 1000);
		}
	}

	/**
	 * Slide next
	 */
	slideNext() {
		clearInterval(this.interval);

		if (this.currentQuestion.showResults && !this.currentQuestion.showGraphic) {
			this.currentQuestion.showGraphic = true;
		} else {
			if (this.indexSlide >= this.countQuestions(this.quiz.questions)) {
				if (this.checkQuizAnswered()) {
					// All quiz answered show last page of results
					// this.presentAlertConfirm();
					this.showLastPageResume = true;
				}
			} else {
				this.currentQuestion.showGraphic = false;
				this.indexSlide++;
				this.currentQuestion = { ...this.quiz.questions[this.indexSlide] };
				this.mySlider.nativeElement.swiper.enable();
				this.mySlider.nativeElement.swiper.slideNext();
				this.mySlider.nativeElement.swiper.disable();

				this.progressBar();
				this.startCountdown();
			}
		}
	}

	/**
	 * Slide previous
	 */
	slidePrevious() {
		if (this.indexSlide > 1) {
			this.indexSlide--;
			this.currentQuestion.showGraphic = false;
			this.currentQuestion = { ...this.quiz.questions[this.indexSlide] };
			this.mySlider.nativeElement.swiper.enable();
			this.mySlider.nativeElement.swiper.slidePrev();
			this.mySlider.nativeElement.swiper.disable();
			this.progressBar();
		}
	}

	/**
	 * Send result of a question
	 * @param question
	 */
	async sendResult(question: IQuizQuestion) {
		this.submitting = true;
		const data = this.questionsForm.getRawValue();
		const questionResult = data[question.uid];
		const quizResult: IQuizQuestionResult = this.getResultOfQuestion(question);
		let newResult: IQuizQuestionResult = null;
		let type: string = "";

		if (quizResult && this.quiz.changeAnswers) {
			newResult = { ...quizResult };
			if (question.type === "oneSelect") {
				newResult = Object.assign(newResult, {
					oneSelect: {
						answerId: questionResult
					}
				});
			} else if (question.type === "multipleSelect") {
				// newResult.multipleSelect.answersIds = questionResult;
				newResult = Object.assign(newResult, {
					multipleSelect: {
						answersIds: questionResult
					}
				});
			}
			type = "update";
		} else {
			newResult = {
				uid: this.SFirestore.createId(`events/${this.eventId}/modules/${this.moduleId}/quizs`),
				creationDate: DateTime.local().toISO(),
				eventId: this.eventId,
				moduleId: this.moduleId,
				multipleSelect: {
					answersIds: question.type === "multipleSelect" ? questionResult : null
				},
				oneSelect: {
					answerId: question.type === "oneSelect" ? questionResult : null
				},
				questionId: question.uid,
				quizId: this.quizId,
				type: question.type,
				userId: this.eventUser.uid
			};
			type = "new";
		}
		try {
			if (type === "new") {
				await this.SQuizs.createQuizResults(this.eventId, this.moduleId, this.quizId, newResult);
				// Anlytics
				this.SAnalytics.sendQuiz(this.eventId, this.moduleId, this.eventUser, this.quizId, newResult);
			} else if (type === "update") {
				await this.SQuizs.updateQuizResults(this.eventId, this.moduleId, this.quizId, newResult);

				// Anlytics
				this.SAnalytics.sendQuiz(this.eventId, this.moduleId, this.eventUser, this.quizId, newResult);
			}
			if (this.quiz.weight && this.eventUser) {
				question.answers[0].weight;
				const weight =
					question.type === "oneSelect"
						? question.answers.find((answer) => answer.uid === questionResult).weight
						: question.answers
								.filter((answer) => questionResult.includes(answer.uid))
								.reduce((sum, answer) => sum + answer.weight, 0);
				const pointRecord: IPointRecord = {
					uid: "",
					creationDate: DateTime.local().toISO(),
					eventId: this.eventId,
					fixedPoints: weight,
					moduleId: this.eventUser ? this.eventUser.moduleId : "",
					options: {
						moduleId: this.moduleId,
						answerId: question.type === "oneSelect" ? questionResult : null,
						answerIds: question.type === "multipleSelect" ? questionResult : null,
						quizId: this.quizId,
						questionId: question.uid,
						questionType: question.type
					},
					type: TypeModule.QUIZ,
					userId: this.eventUser ? this.eventUser.uid : ""
				};
				if (type === "update") {
					await this.SPoints.deleteEventUserPointRecord(this.eventId, pointRecord);
				}
				await this.SPoints.createEventUserPointRecord(this.eventId, pointRecord);
			}
			this.SUtility.presentToast(this.STranslate.instant("quizs.result_send"), 3000, "bottom", "success");
			setTimeout(() => {
				this.slideNext();
			}, 1000);
			this.submitting = false;
		} catch (error) {
			this.SUtility.presentToast(this.STranslate.instant("quizs.cannot_send_quiz"), 3000, "bottom", "danger");
			this.submitting = false;
		}
	}

	async presentAlertConfirm() {
		const alert = await this.alertCtrl.create({
			header: this.STranslate.instant("alerts.thankyou_answer"),
			message: this.STranslate.instant("alerts.answer_saved_successfully"),
			buttons: [
				{
					text: "Ok",
					handler: () => {
						this.goBackToList();
					}
				}
			],
			backdropDismiss: false
		});

		await alert.present();

		clearInterval(this.interval);
	}

	/**
	 * Go back to quizs list
	 */
	goBackToList() {
		if (this.componentMode) {
			this.changeView.emit({ segmentType: "quizs", type: "quizs", uid: "" });
		} else {
			this.SUtility.callGoBackOnHeader();
		}
	}

	/**
	 * Count questions without plainText
	 */
	countQuestions(questions) {
		// return questions.filter((question) => question.type !== "plainText").length;
		return questions.length;
	}

	countanswerableQuestions(questions) {
		return questions.filter((question) => question.visibility && question.type !== "plainText").length;
	}

	getTotalGoodAnswers() {
		return this.quiz.questions
			.filter((quiz) => quiz.type !== "plainText")
			.filter((question) => {
				const hasOneCorrectAnswer = question.answers.some((answer) => answer.correct);
				const correctAnswers = question.answers.filter((answer) => answer.correct);
				const result = this.getResultOfQuestion(question);
				if (question.type === "oneSelect") {
					return hasOneCorrectAnswer &&
						result &&
						result.oneSelect &&
						correctAnswers.find((answer) => result.oneSelect.answerId === answer.uid)
						? true
						: false;
				} else {
					return hasOneCorrectAnswer &&
						result &&
						result.multipleSelect &&
						correctAnswers.filter((answer) => result.multipleSelect.answersIds.includes(answer.uid))
							.length === correctAnswers.length
						? true
						: false;
				}
			});
	}

	calcPercentageGoodAnswers() {
		const goodAnswers = this.getTotalGoodAnswers();

		return goodAnswers.length > 0
			? Math.round((goodAnswers.length / this.countanswerableQuestions(this.quiz.questions)) * 100)
			: 0;
	}

	async redoQuiz(later: boolean) {
		try {
			// Reset all results of user
			await firstValueFrom(
				this.SQuizs.clearResultQuizOfEventUser(this.eventId, this.moduleId, this.quizId, this.eventUser.uid)
			);
			this.indexSlide = 1;
			this.counter = 0;
			this.progress = 0;
			clearInterval(this.interval);
			this.currentQuestion = this.quiz.questions.length > 0 ? { ...this.quiz.questions[0] } : null;
			this.progressBar();
			this.startCountdown();

			this.showLastPageResume = false;
			this.quizAnsweredInInit = false;

			if (later) {
				this.goBackToList();
			}
		} catch (error) {
			this.snackbar.open(this.STranslate.instant("snackbar.error_occured"), "", {
				duration: 3000,
				panelClass: "error-snackbar"
			});
		}
	}
}
